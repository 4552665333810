<template>
  <div class="inviteExternalContact">
    <background />
    <img
      v-if="inviteMsg.userHeadImg"
      :src="getImgUrl(inviteMsg.userHeadImg)"
      alt
      class="headerImg"
    />
    <img v-else src="@/assets/imgs/person.svg" alt class="headerImg" />
    <div class="title">{{ inviteMsg.name }}邀请你成为外部联系人</div>
    <div class="des">{{ inviteMsg.comName }}</div>
    <div class="content">
      <div class="label"><span>*</span>选择你成为企业外部联系人的身份</div>
      <div class="identity-select">
        <div @click="changeIdentity" class="identity-select-text-wrap">
          <div class="text">{{ identity }}</div>
          <div class="icon" :class="showIdentitySelect ? 'icon2' : ''"></div>
        </div>
        <ul class="identity-list" v-if="showIdentitySelect">
          <li class="identity-item" @click="selectOne(null)">个人身份</li>
          <li
            class="identity-item"
            v-for="(item, index) in myInfo.companyInfoVoList"
            :key="index"
            @click="selectOne(item.comId)"
          >
            {{ item.comName }}
          </li>
        </ul>
      </div>
      <div class="label">发送验证申请，等待管理员通过</div>
      <div class="input">
        <van-field
          v-model="params.remarks"
          placeholder="最多输入20字"
          maxlength="20"
        />
      </div>
      <div class="addPeople" @click="addPeople">申请成为企业外部联系人</div>
      <div class="addPeople-sub">
        当前登录手机号：{{ userInfo.telephone }}
        <span v-if="userInfo.creditStatus !== 1">（未实名）</span>
        <span v-else>（{{ userInfo.name }}）</span>
      </div>
      <div class="changeAccount" @click="changeAcount">我要切换账号</div>
    </div>
  </div>
</template>
<script>
  import Api from '@/api/address'
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        value: '',
        showIdentitySelect: false,
        inviteMsg: {
          userHeadImg: '',
          name: '',
          comName: '',
        },
        params: {
          comId: null,
          friendType: 0,
          ownerId: 0,
          ownerType: 1,
          remarks: null,
        },
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        myInfo: state => state.user.myInfo,
      }),
      identity() {
        const { comId } = this.params
        if (comId === null) return '个人身份'
        return this.myInfo.companyInfoVoList.find(item => item.comId === comId)
          .comName
      },
    },
    created() {
      const { userHeadImg, name, comName, comId, userId } = this.$jsonPase(
        window.sessionStorage.getItem('inviteExternal')
      )
      this.params.ownerId = comId
      this.inviteMsg = {
        userHeadImg,
        name,
        comName,
      }
    },
    methods: {
      changeIdentity() {
        this.showIdentitySelect = !this.showIdentitySelect
      },
      selectOne(id) {
        this.params.comId = id
        this.showIdentitySelect = false
      },
      addPeople() {
        Api.applyFriend(this.params).then(res => {
          // if (this.$isWeiXin) {
          //   this.$router.push('/wxInner');
          // } else {
          //   this.$router.push('/notWxInner');
          // }
          this.$router.push('/notWxInner')
        })
      },
      changeAcount() {
        this.$router.push('/inviteExternalContact')
      },
    },
  }
</script>
<style lang="less" scoped>
  .inviteExternalContact {
    padding-top: 30px;
    .headerImg {
      display: flex;
      margin: 0 auto;
      margin-bottom: 30px;
      width: 70px;
      height: 70px;
      border: 2px solid rgba(255, 255, 255, 1);
    }
    .title {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      line-height: 30px;
      text-align: center;
      margin-bottom: 10px;
    }
    .des {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      line-height: 22px;
      text-align: center;
      margin-bottom: 37px;
    }
    .content {
      padding: 0 24px;
      .label {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(103, 114, 131, 1);
        line-height: 20px;
        margin-bottom: 10px;
        span {
          color: rgba(241, 86, 67, 1);
        }
      }
      .identity-select {
        margin-bottom: 24px;
        width: 327px;
        height: 46px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(226, 228, 234, 1);
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .identity-select-text-wrap {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .text {
          height: 21px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(17, 26, 52, 1);
          line-height: 21px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .icon {
          width: 12px;
          height: 7px;
          background: url('../../assets/imgs/shape@2x.png') no-repeat center;
          background-size: 12px 7px;
        }
        .icon2 {
          transform: rotate(180deg);
        }
        position: relative;
        .identity-list {
          position: absolute;
          top: 49px;
          z-index: 2;
          left: 0;
          width: 327px;
          min-height: 48px;
          background: rgba(255, 255, 255, 1);
          border-radius: 4px;
          border: 1px solid rgba(226, 228, 234, 1);
          .identity-item {
            padding-left: 16px;
            height: 48px;
            background: rgba(255, 255, 255, 1);
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(17, 26, 52, 1);
            line-height: 48px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .input {
        width: 327px;
        height: 46px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(226, 228, 234, 1);
        margin-bottom: 38px;
        display: flex;
        align-items: center;
      }
      .addPeople {
        width: 280px;
        height: 44px;
        background: rgba(22, 118, 255, 1);
        border-radius: 4px;
        margin: 0 auto;
        margin-bottom: 16px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 44px;
        text-align: center;
      }
      .addPeople-sub {
        height: 24px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(103, 114, 131, 1);
        line-height: 24px;
        text-align: center;
        margin-bottom: 16px;
      }
      .changeAccount {
        height: 24px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(22, 118, 255, 1);
        line-height: 24px;
        text-align: center;
      }
    }
  }
</style>
